// import React from 'react';
// import styles from './styles.module.css';

// export default function MealCard({ mealInstance, onClick }) {
//     const { category, name } = mealInstance.attributes.meal.data.attributes;
//     const imageUrl = mealInstance.attributes.meal.data.attributes.meal_images.data[0].attributes.image.data.attributes.url;

//     return (
//         <div className={styles.card} onClick={onClick} style={{ backgroundImage: `url(${imageUrl})` }}>
//             <div className={styles.cardOverlay}>
//                 <h3>{name}</h3>
//                 <p>{category}</p>
//             </div>
//         </div>
//     );
// }

// import React from 'react';
// import styles from './styles.module.css';

// export default function MealCard({ mealData, isMealInstance, onClick }) {
//     const mealAttributes = isMealInstance ? mealData.attributes.meal.data.attributes : mealData.attributes;
//     const { category, name } = mealAttributes;
//     const imageUrl = mealAttributes.meal_images?.data[0]?.attributes?.image?.data?.attributes?.url;

//     return (
//         <div className={styles.card} onClick={onClick} style={{ backgroundImage: `url(${imageUrl})` }}>
//             <div className={styles.cardOverlay}>
//                 <h2>{name}</h2>
//                 <p>{category}</p>
//             </div>
//         </div>
//     );
// }

// MealCard.js
import React from 'react';
import styles from './styles.module.css';

export default function MealCard({ mealData, onClick }) {
    const mealAttributes = mealData.isMealInstance ? mealData.attributes.meal.data.attributes : mealData.attributes;
    const { category, name } = mealAttributes;
    const imageUrl = mealAttributes.meal_images?.data[0]?.attributes?.image?.data?.attributes?.url;

    return (
        <div className={styles.card} onClick={onClick}>
            <div className={styles.imageDiv} style={{ backgroundImage: `url(${imageUrl})` }}>
            </div>
            <div className={styles.cardOverlay}>
                <div className={styles.subH}>{name}</div>
                <div className={styles.text}>{category}</div>
            </div>
        </div>
    );
}

