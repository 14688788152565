import React, { useState } from 'react';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import css from './styles.module.css';
import UpdateExerciseForm from '../global/forms/updateExercise';
import { getLocalStorageItem } from '../global/storage';

const ExerciseCards = ({ exercises }) => {
    const [selectedExercise, setSelectedExercise] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const userInfo = getLocalStorageItem('limitless-user');
    const [showUpdateForm, setShowUpdateForm] = useState(false);

    const isAdmin = userInfo.user.isAdmin;

    const handleClientClick = (exercise) => {
        setSelectedExercise(exercise);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedExercise(null);
        setShowUpdateForm(false);
    };

    console.log('exercises', exercises);

    return(
            <div className={css.cardContainer}>
                {exercises.length > 0 ? (
                    exercises.map((exercise) => {
                        //eslint-disable-next-line
                        const { name, muscles, description, image } = exercise.attributes;
                        // const imageUrl = image?.data[0]?.attributes?.url;

                        return (
                            <div
                                key={exercise.id}
                                className={css.card}
                                onClick={() => handleClientClick(exercise)}
                                style={{backgroundImage: `url(${exercise.attributes.image.data[0].attributes.url})`}}
                            >
                                <div className={css.infoDiv}>
                                    <div className={css.name}>{name}</div>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className={css.noClients}>
                        Finner ingen øvelser
                    </div>
                )}
                {/* Modal for å vise klientinformasjon */}
            <Modal className={css.modal} size="lg" show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Øvelsesdetaljer</Modal.Title>
                </Modal.Header>
                {/* {selectedExercise && (
                    <Modal.Body>
                        <div className={css.modalBody}>
                            <div className={css.modalImageContainer}>
                                <Image className={css.modalProfilePicture} fluid alt="client-image" src={selectedExercise.attributes.image.data[0].attributes.url} />
                            </div>
                            <div className={css.modalInfo}>
                                <p><strong>Navn:</strong> {selectedExercise.attributes.name}</p>
                                <p><strong>Muskelgruppe: </strong> 
                                     {selectedExercise.attributes.muscles && selectedExercise.attributes.muscles.data.length > 0 
                                        ? selectedExercise.attributes.muscles.data[0].attributes.Name
                                        : "Ingen muskelgruppe knyttet"}
                                </p>
                            </div>
                        </div>
                    </Modal.Body>
                )} */}
                {selectedExercise && !showUpdateForm && (
                    <Modal.Body>
                        <div className={css.modalBody}>
                            <div className={css.modalImageContainer}>
                                <Image
                                    className={css.modalProfilePicture}
                                    fluid
                                    alt="exercise-image"
                                    src={selectedExercise.attributes.image?.data[0]?.attributes?.url || ''}
                                />
                            </div>
                            <div className={css.modalInfo}>
                                <p>
                                    <strong>Navn:</strong> {selectedExercise.attributes.name}
                                </p>
                                <p>
                                    <strong>Muskelgruppe: </strong>
                                    {selectedExercise.attributes.muscles?.data.length > 0
                                        ? selectedExercise.attributes.muscles.data[0].attributes.Name
                                        : 'Ingen muskelgruppe knyttet'}
                                </p>
                                <p>
                                    <strong>Beskrivelse:</strong> {selectedExercise.attributes.description}
                                </p>
                            </div>
                            {isAdmin === true && (
                                <UpdateExerciseForm exerciseId={selectedExercise.id} />
                            )}
                        </div>
                    </Modal.Body>
                )}
                <Modal.Footer>
                    <div className={css.closeButton} onClick={handleCloseModal}>
                        Lukk
                    </div>
                </Modal.Footer>
            </Modal>
            </div>
    )
};

export default ExerciseCards;