import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import { axiosAuthFetch, axiosImageAuthFetch } from '../../../../helpers/axios';
import { API_BASE_URL } from '../../../../constants';
import Loader from '../../loader';

export default function UpdateExerciseForm({ exerciseId }) {
    const [name, setName] = useState('');
    const [file, setImage] = useState();
    const [muscleGroups, setMuscleGroups] = useState([]);
    const [description, setDescription] = useState('');
    const [selectedMuscleGroup, setSelectedMuscleGroup] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchExerciseData = async () => {
            try {
                setIsLoading(true);

                // Hent muskelgrupper
                const muscleGroupsResponse = await axiosAuthFetch(`${API_BASE_URL}/muscles`, {}, 'GET');
                setMuscleGroups(muscleGroupsResponse.data);

                // Hent eksisterende øvelsesdata
                const exerciseResponse = await axiosAuthFetch(`${API_BASE_URL}/exercises/${exerciseId}?populate=*`, {}, 'GET');
                console.log(exerciseResponse);
                const { name, description } = exerciseResponse.data.attributes;

                setName(name);
                setDescription(description);
                setSelectedMuscleGroup(exerciseResponse.data.attributes.muscles.data[0].id || '');
            } catch (error) {
                console.error('Feil ved henting av data', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchExerciseData();
    }, [exerciseId]);

    const handleExerciseNameChange = (e) => {
        setName(e.target.value);
    };

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    const handleMuscleGroupChange = (e) => {
        setSelectedMuscleGroup(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleSubmit = async () => {
        try {
            setIsLoading(true);

            const data = {
                name: name,
                muscles: selectedMuscleGroup,
                description: description,
            };

            // Oppdater øvelsesdata
            const updateResponse = await axiosAuthFetch(`${API_BASE_URL}/exercises/${exerciseId}`, { data }, 'PUT');
            console.log(updateResponse);

            // Oppdater bilde hvis nytt bilde er lastet opp
            if (file) {
                const formData = new FormData();
                formData.append('ref', 'api::exercise.exercise');
                formData.append('refId', exerciseId);
                formData.append('field', 'image');
                formData.append('files', file);

                const imageResponse = await axiosImageAuthFetch(`${API_BASE_URL}/upload`, formData, 'POST');
                console.log(imageResponse);
            }

            alert('Øvelse oppdatert!');
        } catch (error) {
            console.error('Feil ved oppdatering av øvelse', error);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>Oppdater øvelse</div>
            <form className={styles.form} onSubmit={(e) => e.preventDefault()}>
                <input
                    className={styles.input}
                    placeholder="Navn på øvelse"
                    value={name}
                    onChange={handleExerciseNameChange}
                />
                <textarea
                    className={styles.input}
                    placeholder="Beskrivelse"
                    value={description}
                    onChange={handleDescriptionChange}
                    rows={8}
                />
                <select
                    className={styles.select}
                    value={selectedMuscleGroup}
                    onChange={handleMuscleGroupChange}
                >
                    <option value="">Velg muskelgruppe</option>
                    {muscleGroups.map((muscle) => (
                        <option key={muscle.id} value={muscle.id}>
                            {muscle.attributes.Name}
                        </option>
                    ))}
                </select>
                <input type="file" onChange={handleImageChange} />
                <div className={styles.submitButton} onClick={handleSubmit}>
                    Oppdater
                </div>
            </form>
        </div>
    );
}
