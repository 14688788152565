// import React, { useState } from 'react';
// import MealCard from '../cards';
// import MealDetail from '../detail';
// import styles from './styles.module.css';

// export default function MealsPage({ mealInstances }) {
    
//     const [selectedMeal, setSelectedMeal] = useState(null);

//     const handleCardClick = (mealInstance) => {
//         setSelectedMeal(mealInstance);
//     };

//     const handleBack = () => {
//         setSelectedMeal(null);
//     };

//     return (
//         <div className={styles.container}>
//             {selectedMeal ? (
//                 <MealDetail mealInstance={selectedMeal} onBack={handleBack} />
//             ) : (
//                 <div className={styles.cardContainer}>
//                     <h1>Dine måltider</h1>
//                     {mealInstances.map(mealInstance => (
//                         <MealCard key={mealInstance.id} mealInstance={mealInstance} onClick={() => handleCardClick(mealInstance)} />
//                     ))}
//                 </div>
//             )}
//         </div>
//     );
// }

// import React, { useState } from 'react';
// import MealCard from '../cards';
// import MealDetail from '../detail';
// import styles from './styles.module.css';

// export default function MealsPage({ mealInstances, isMealInstance }) {
//     const [selectedMeal, setSelectedMeal] = useState(null);

//     const handleCardClick = (meal) => {
//         setSelectedMeal(meal);
//     };

//     const handleBack = () => {
//         setSelectedMeal(null);
//     };

//     return (
//         <div className={styles.container}>
//             {selectedMeal ? (
//                 <MealDetail mealData={selectedMeal} isMealInstance={isMealInstance} onBack={handleBack} />
//             ) : (
//                 <div className={styles.cardContainer}>
//                     <h1>Dine måltider</h1>
//                     {mealInstances.length > 0 ? (
//                         mealInstances.map(mealInstance => (
//                             <MealCard 
//                                 key={mealInstance.id} 
//                                 mealData={mealInstance} 
//                                 isMealInstance={isMealInstance} 
//                                 onClick={() => handleCardClick(mealInstance)} 
//                             />
//                         ))
//                     ) : (
//                         <div>Ingen måltider tilgjengelig</div>
//                     )}
//                 </div>
//             )}
//         </div>
//     );
// }

// MealsPage.js
import React, { useState } from 'react';
import MealCard from '../cards';
import MealDetail from '../detail';
import styles from './styles.module.css';

export default function MealsPage({ meals }) {
    const [selectedMeal, setSelectedMeal] = useState(null);

    const handleCardClick = (meal) => {
        setSelectedMeal(meal);
    };

    const handleBack = () => {
        setSelectedMeal(null);
    };

    return (
        <div className={styles.container}>
            {selectedMeal ? (
                <MealDetail mealData={selectedMeal} onBack={handleBack} />
            ) : (
                <>
                <h1 className="text-center">Dine måltider</h1>
                    <div className={styles.cardContainer}>
                        {meals.length > 0 ? (
                        meals.map(meal => (
                            <MealCard 
                                key={meal.id} 
                                mealData={meal} 
                                onClick={() => handleCardClick(meal)}
                            />
                        ))
                    ) : (
                        <div>Ingen måltider tilgjengelig</div>
                    )}
                    </div>
                </>
            )}
        </div>
    );
}

