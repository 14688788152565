import React from 'react';
import styles from './styles.module.css';
import Image from 'react-bootstrap/Image';

export default function CoachSideMealCard({ meal, onClick, isDesktop }) {

   

    const mealAttributes = meal.attributes;
    console.log(meal);
    const { name, category } = mealAttributes;
    const imageUrl = mealAttributes.meal_images?.data[0]?.attributes?.image?.data?.attributes?.url;

    return (
        <div className={`${isDesktop ? styles.desktopList : styles.mobileCard}`}>
            {isDesktop ? (
                <div className={`${styles.desktopListItem} col-11 justify-content-between`} onClick={onClick}>
                    <div className="col-6 d-flex align-items-center">
                        <div className="col-3 me-2">
                            <Image
                                fluid
                                src={meal.attributes.meal_images.data[0]?.attributes.image.data.attributes.url}
                                alt={meal.attributes.name}
                                className={styles.thumbnail}
                            />
                        </div>
                        <h3 className={styles.mealName}>{meal.attributes.name}</h3>
                    </div>
                    <div className="col-4">
                        <div className={styles.smallText}>
                            Antall klienter: {meal.attributes.clients.data.length}
                        </div>
                    </div>
                </div>
            ):(
            <div className={styles.card} onClick={onClick}>
                <div className={styles.imageDiv} style={{ backgroundImage: `url(${imageUrl})` }}>
                </div>
                <div className={styles.cardOverlay}>
                    <div className={styles.subH}>{name}</div>
                    <div className={styles.text}>{category}</div>
                </div>
            </div>
            )}
        </div>
    );
}