// import React, { useState, useEffect } from 'react';
// import styles from './styles.module.scss';
// import Modal from 'react-bootstrap/Modal';

// export default function VariantCard({
//   variant,
//   index,
//   deleteVariant,
//   clients,
//   allIngredients,
//   updateVariant,
//   createVariant,
//   mealId, // Legg til mealId hvis nødvendig
// }) {
//   const [showModal, setShowModal] = useState(false);

//   // Tilpasser initialiseringen av customIngredients for å matche strukturen i ClientCard
//   const initialCustomIngredients = (variant?.attributes?.custom_ingredients || []).map(
//     (ingredient) => ({
//       id: ingredient.id || null,
//       name: ingredient.ingredient_name,
//       amount: ingredient.amount,
//       unit: ingredient.unit,
//       macros: ingredient.macros,
//       ingredientId: ingredient.ingredients ? ingredient.ingredients[0] : null,
//     })
//   );

//   const [customIngredients, setCustomIngredients] = useState(initialCustomIngredients);
//   const [totalMacros, setTotalMacros] = useState({ protein: 0, carbs: 0, fat: 0, kcals: 0 });
//   const [ingredientSearch, setIngredientSearch] = useState('');
//   const [filteredIngredients, setFilteredIngredients] = useState([]);
//   const [selectedIngredient, setSelectedIngredient] = useState(null);
//   const [newIngredientAmount, setNewIngredientAmount] = useState('');
//   const [newIngredientUnit, setNewIngredientUnit] = useState('g');
//   const [newVariantName, setNewVariantName] = useState(variant?.attributes?.variant_name || '');

//   const handleIngredientSearch = (e) => {
//     const searchValue = e.target.value.toLowerCase();
//     setIngredientSearch(searchValue);
//     setFilteredIngredients(
//       searchValue
//         ? allIngredients.filter((ingredient) =>
//             ingredient.attributes.name.toLowerCase().includes(searchValue)
//           )
//         : []
//     );
//   };

//   const handleAddIngredient = () => {
//     if (!selectedIngredient || !newIngredientAmount) return;

//     const newIngredient = {
//       id: null,
//       name: selectedIngredient.attributes.name,
//       amount: parseFloat(newIngredientAmount),
//       unit: newIngredientUnit,
//       macros: selectedIngredient.attributes.macros[0],
//       ingredientId: selectedIngredient.id,
//     };

//     setCustomIngredients([...customIngredients, newIngredient]);
//     setIngredientSearch('');
//     setSelectedIngredient(null);
//     setNewIngredientAmount('');
//     setNewIngredientUnit('g');
//   };

//   const handleRemoveIngredient = (index) => {
//     const updatedIngredients = customIngredients.filter((_, i) => i !== index);
//     setCustomIngredients(updatedIngredients);
//   };

//   const handleVariantNameInput = (e) => {
//     setNewVariantName(e.target.value);
//   };

//   const handleIngredientChange = (index, newAmount) => {
//     const updatedIngredients = [...customIngredients];
//     updatedIngredients[index] = {
//       ...updatedIngredients[index],
//       amount: parseFloat(newAmount) || '', // Setter til 0 hvis input er tomt eller ugyldig
//     };
//     setCustomIngredients(updatedIngredients);
//   };

//   const calculateMacros = () => {
//     let totalProtein = 0;
//     let totalCarbs = 0;
//     let totalFat = 0;
//     let totalKcals = 0;

//     customIngredients.forEach((ingredient) => {
//       const { amount, macros, unit } = ingredient;

//       if (!macros) return;

//       if (unit === 'stk') {
//         totalProtein += macros.protein * amount;
//         totalCarbs += macros.carbs * amount;
//         totalFat += macros.fat * amount;
//         totalKcals += macros.kcals * amount;
//       } else if (unit === 'g') {
//         totalProtein += (macros.protein / 100) * amount;
//         totalCarbs += (macros.carbs / 100) * amount;
//         totalFat += (macros.fat / 100) * amount;
//         totalKcals += (macros.kcals / 100) * amount;
//       } else if (unit === 'dl') {
//         const gramsEquivalent = amount * 100;
//         totalProtein += (macros.protein / 100) * gramsEquivalent;
//         totalCarbs += (macros.carbs / 100) * gramsEquivalent;
//         totalFat += (macros.fat / 100) * gramsEquivalent;
//         totalKcals += (macros.kcals / 100) * gramsEquivalent;
//       }
//     });

//     setTotalMacros({
//       protein: Math.round(totalProtein),
//       carbs: Math.round(totalCarbs),
//       fat: Math.round(totalFat),
//       kcals: Math.round(totalKcals),
//     });
//   };

//   useEffect(() => {
//     calculateMacros();
//     //eslint-disable-next-line
//   }, [customIngredients]);

//   const handleSaveVariant = async (isUpdate) => {
//     const ingredientsForVariant = customIngredients.map((ingredient) => ({
//       amount: ingredient.amount,
//       unit: ingredient.unit,
//       ingredientId: ingredient.ingredientId || ingredient.id,
//       name: ingredient.name,
//       macros: ingredient.macros,
//       meal: [mealId], // Inkluder mealId hvis nødvendig
//     }));

//     if (isUpdate && variant) {
//       await updateVariant({
//         variantId: variant.id,
//         variantName: newVariantName,
//         ingredients: ingredientsForVariant,
//       });
//     } else {
//       await createVariant({
//         variantName: newVariantName,
//         mealId,
//         ingredients: ingredientsForVariant,
//       });
//     }

//     setShowModal(false);
//   };

//   return (
//     <>
//       <div className={styles.variantCard} onClick={() => setShowModal(true)} key={`${variant?.id}-${index}`}>
//         <div className={styles.variantName}>{variant?.attributes?.variant_name || 'Ny variant'}</div>
//       </div>
//       <Modal show={showModal} onHide={() => setShowModal(false)} centered>
//         <Modal.Header closeButton>
//           <Modal.Title>Tilpass mengder for {newVariantName || 'Ny variant'}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body className={styles.container2}>
//           <h3>Ingredienser</h3>
//           {customIngredients.map((ingredient, index) => (
//             <div key={index} className={styles.ingredientAdjustContainer}>
//               <label>{ingredient.name}</label>
//               <input
//                 type="number"
//                 value={ingredient.amount}
//                 onChange={(e) => handleIngredientChange(index, e.target.value)}
//                 className={styles.ingredientInput}
//               />
//               <span>{ingredient.unit}</span>
//               <button onClick={() => handleRemoveIngredient(index)} className={styles.removeIngredientButton}>
//                 Fjern
//               </button>
//             </div>
//           ))}

//           <div className={styles.addIngredientContainer}>
//             <h4 className="my-4">Legg til ingrediens</h4>
//             <input
//               type="text"
//               value={ingredientSearch}
//               onChange={handleIngredientSearch}
//               placeholder="Søk etter ingrediens"
//               className={styles.ingredientSearchInput}
//             />
//             <div className={styles.filteredIngredients}>
//               {filteredIngredients.map((ingredient) => (
//                 <div
//                   key={ingredient.id}
//                   className={`${styles.ingredientOption} ${
//                     selectedIngredient && selectedIngredient.id === ingredient.id ? styles.selected : ''
//                   }`}
//                   onClick={() => setSelectedIngredient(ingredient)}
//                 >
//                   {ingredient.attributes.name}
//                 </div>
//               ))}
//             </div>
//             {selectedIngredient && (
//               <div className={styles.addIngredientDetails}>
//                 <input
//                   type="number"
//                   value={newIngredientAmount}
//                   onChange={(e) => setNewIngredientAmount(e.target.value)}
//                   placeholder="Mengde"
//                   className={styles.ingredientAmountInput}
//                 />
//                 {newIngredientUnit}
//                 <button onClick={handleAddIngredient} className={styles.addIngredientButton}>
//                   Legg til ingrediens
//                 </button>
//               </div>
//             )}
//           </div>

//           <div className={styles.macroSummary}>
//             <h4>Totale Makroer</h4>
//             <p>
//               <strong>Protein:</strong> {totalMacros.protein}g
//             </p>
//             <p>
//               <strong>Karbohydrater:</strong> {totalMacros.carbs}g
//             </p>
//             <p>
//               <strong>Fett:</strong> {totalMacros.fat}g
//             </p>
//             <p>
//               <strong>Kalorier:</strong> {totalMacros.kcals} kcal
//             </p>
//           </div>
//           <div className={styles.nameInput}>
//             <label>Navn på variant</label>
//             <input
//               className={styles.ingredientSearchInput}
//               placeholder="Navn på varianten"
//               value={newVariantName}
//               onChange={handleVariantNameInput}
//             />
//           </div>
//         </Modal.Body>
//         <Modal.Footer className={styles.modalCustomFooter}>
//           <button
//             className={styles.submitButton}
//             disabled={!newVariantName.trim()}
//             onClick={() => handleSaveVariant(variant ? true : false)}
//           >
//             {variant ? 'Oppdater variant' : 'Lagre som ny variant'}
//           </button>
//           {variant && (
//             <button onClick={() => deleteVariant(variant.id)} className={styles.deleteVariantButton}>
//               Slett variant
//             </button>
//           )}
//           <button onClick={() => setShowModal(false)} className={styles.cancelButton}>
//             Avbryt
//           </button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// }


// import React, { useState, useEffect } from 'react';
// import styles from './styles.module.scss';
// import Modal from 'react-bootstrap/Modal';

// export default function VariantCard({
//   variant,
//   index,
//   deleteVariant,
//   clients,
//   allIngredients,
//   updateVariant,
//   createVariant,
//   mealId,
//   addClientToVariant,
//   removeClientFromVariant,
// }) {
//   const [showModal, setShowModal] = useState(false);

//   // Initialiserer customIngredients med riktig struktur
//   const initialCustomIngredients = (variant?.attributes?.custom_ingredients || []).map(
//     (ingredient) => ({
//       id: null,
//       name: ingredient.ingredient_name,
//       amount: ingredient.amount,
//       unit: ingredient.unit,
//       macros: ingredient.macros,
//       ingredientId: ingredient.ingredients ? ingredient.ingredients[0] : null,
//     })
//   );

//   const [customIngredients, setCustomIngredients] = useState(initialCustomIngredients);
//   const [totalMacros, setTotalMacros] = useState({ protein: 0, carbs: 0, fat: 0, kcals: 0 });
//   const [variantClients, setVariantClients] = useState(variant?.attributes?.clients?.data || []);
//   const [ingredientSearch, setIngredientSearch] = useState('');
//   const [filteredIngredients, setFilteredIngredients] = useState([]);
//   const [selectedIngredient, setSelectedIngredient] = useState(null);
//   const [newIngredientAmount, setNewIngredientAmount] = useState('');
//   const [newIngredientUnit, setNewIngredientUnit] = useState('g');
//   const [newVariantName, setNewVariantName] = useState(variant?.attributes?.variant_name || '');

//   // For klient-søk og valg
//   const [clientSearch, setClientSearch] = useState('');
//   const [filteredClients, setFilteredClients] = useState([]);
//   const [selectedClient, setSelectedClient] = useState(null);

//   // Oppdaterer filtrerte klienter basert på søk
//   useEffect(() => {
//     const searchValue = clientSearch.toLowerCase();
//     setFilteredClients(
//       searchValue
//         ? clients.filter((client) =>
//             client.attributes.username.toLowerCase().includes(searchValue)
//           )
//         : []
//     );
//   }, [clientSearch, clients]);

//   const handleIngredientSearch = (e) => {
//     const searchValue = e.target.value.toLowerCase();
//     setIngredientSearch(searchValue);
//     setFilteredIngredients(
//       searchValue
//         ? allIngredients.filter((ingredient) =>
//             ingredient.attributes.name.toLowerCase().includes(searchValue)
//           )
//         : []
//     );
//   };

//   const handleAddIngredient = () => {
//     if (!selectedIngredient || !newIngredientAmount) return;

//     const newIngredient = {
//       id: null,
//       name: selectedIngredient.attributes.name,
//       amount: parseFloat(newIngredientAmount),
//       unit: newIngredientUnit,
//       macros: selectedIngredient.attributes.macros[0],
//       ingredientId: selectedIngredient.id,
//     };

//     setCustomIngredients([...customIngredients, newIngredient]);
//     setIngredientSearch('');
//     setSelectedIngredient(null);
//     setNewIngredientAmount('');
//     setNewIngredientUnit('g');
//   };

//   const handleRemoveIngredient = (index) => {
//     const updatedIngredients = customIngredients.filter((_, i) => i !== index);
//     setCustomIngredients(updatedIngredients);
//   };

//   const handleVariantNameInput = (e) => {
//     setNewVariantName(e.target.value);
//   };

//   const handleIngredientChange = (index, newAmount) => {
//     const updatedIngredients = [...customIngredients];
//     updatedIngredients[index] = {
//       ...updatedIngredients[index],
//       amount: parseFloat(newAmount) || '',
//     };
//     setCustomIngredients(updatedIngredients);
//   };

//   const calculateMacros = () => {
//     let totalProtein = 0;
//     let totalCarbs = 0;
//     let totalFat = 0;
//     let totalKcals = 0;

//     customIngredients.forEach((ingredient) => {
//       const { amount, macros, unit } = ingredient;

//       if (!macros) return;

//       if (unit === 'stk') {
//         totalProtein += macros.protein * amount;
//         totalCarbs += macros.carbs * amount;
//         totalFat += macros.fat * amount;
//         totalKcals += macros.kcals * amount;
//       } else if (unit === 'g') {
//         totalProtein += (macros.protein / 100) * amount;
//         totalCarbs += (macros.carbs / 100) * amount;
//         totalFat += (macros.fat / 100) * amount;
//         totalKcals += (macros.kcals / 100) * amount;
//       } else if (unit === 'dl') {
//         const gramsEquivalent = amount * 100;
//         totalProtein += (macros.protein / 100) * gramsEquivalent;
//         totalCarbs += (macros.carbs / 100) * gramsEquivalent;
//         totalFat += (macros.fat / 100) * gramsEquivalent;
//         totalKcals += (macros.kcals / 100) * gramsEquivalent;
//       }
//     });

//     setTotalMacros({
//       protein: Math.round(totalProtein),
//       carbs: Math.round(totalCarbs),
//       fat: Math.round(totalFat),
//       kcals: Math.round(totalKcals),
//     });
//   };

//   useEffect(() => {
//     calculateMacros();
//     //eslint-disable-next-line
//   }, [customIngredients]);

//   const handleSaveVariant = async (isUpdate) => {
//     const ingredientsForVariant = customIngredients.map((ingredient) => ({
//       amount: ingredient.amount,
//       unit: ingredient.unit,
//       ingredientId: ingredient.ingredientId || ingredient.id,
//       name: ingredient.name,
//       macros: ingredient.macros,
//       meal: [mealId],
//     }));

//     if (isUpdate && variant) {
//       await updateVariant({
//         variantId: variant.id,
//         variantName: newVariantName,
//         ingredients: ingredientsForVariant,
//       });
//     } else {
//       await createVariant({
//         variantName: newVariantName,
//         mealId,
//         ingredients: ingredientsForVariant,
//       });
//     }

//     setShowModal(false);
//   };

//   // Håndterer tillegg av klient til varianten
// //   const handleAddClient = async () => {
// //     if (!selectedClient) return;
// //     await addClientToVariant({ variantId: variant.id, clientId: selectedClient.id });
// //     // Oppdaterer lokal tilstand
// //     setVariantClients([...variantClients, selectedClient]);
// //     setSelectedClient(null);
// //     setClientSearch('');
// //   };

// const handleAddClient = async () => {
//     if (!selectedClient) return;
  
//     // Bekreft at klienten vil bli fjernet fra andre varianter/hovedmåltidet
//     const confirmed = window.confirm(`Klienten ${selectedClient.attributes.username} vil bli fjernet fra andre varianter av dette måltidet og lagt til denne varianten. Fortsette?`);
//     if (!confirmed) return;
  
//     await addClientToVariant({ variantId: variant.id, clientId: selectedClient.id });
//     // Oppdaterer lokal tilstand
//     setVariantClients([...variantClients, selectedClient]);
//     setSelectedClient(null);
//     setClientSearch('');
//   };
  
//   // Håndterer fjerning av klient fra varianten
//   const handleRemoveClient = async (clientId) => {
//     await removeClientFromVariant({ variantId: variant.id, clientId });
//     // Oppdaterer lokal tilstand
//     setVariantClients(variantClients.filter((client) => client.id !== clientId));
//   };

//   return (
//     <>
//       <div
//         className={styles.variantCard}
//         onClick={() => setShowModal(true)}
//         key={`${variant?.id}-${index}`}
//       >
//         <div className={styles.variantName}>{variant?.attributes?.variant_name || 'Ny variant'}</div>
//       </div>
//       <Modal show={showModal} onHide={() => setShowModal(false)} centered>
//         <Modal.Header closeButton>
//           <Modal.Title>Tilpass mengder for {newVariantName || 'Ny variant'}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body className={styles.container2}>
//           <h3>Ingredienser</h3>
//           {customIngredients.map((ingredient, index) => (
//             <div key={index} className={styles.ingredientAdjustContainer}>
//               <label>{ingredient.name}</label>
//               <input
//                 type="number"
//                 value={ingredient.amount}
//                 onChange={(e) => handleIngredientChange(index, e.target.value)}
//                 className={styles.ingredientInput}
//               />
//               <span>{ingredient.unit}</span>
//               <button
//                 onClick={() => handleRemoveIngredient(index)}
//                 className={styles.removeIngredientButton}
//               >
//                 Fjern
//               </button>
//             </div>
//           ))}

//           {/* Legg til ny ingrediens */}
//           <div className={styles.addIngredientContainer}>
//             <h4 className="my-4">Legg til ingrediens</h4>
//             <input
//               type="text"
//               value={ingredientSearch}
//               onChange={handleIngredientSearch}
//               placeholder="Søk etter ingrediens"
//               className={styles.ingredientSearchInput}
//             />
//             <div className={styles.filteredIngredients}>
//               {filteredIngredients.map((ingredient) => (
//                 <div
//                   key={ingredient.id}
//                   className={`${styles.ingredientOption} ${
//                     selectedIngredient && selectedIngredient.id === ingredient.id
//                       ? styles.selected
//                       : ''
//                   }`}
//                   onClick={() => setSelectedIngredient(ingredient)}
//                 >
//                   {ingredient.attributes.name}
//                 </div>
//               ))}
//             </div>
//             {selectedIngredient && (
//               <div className={styles.addIngredientDetails}>
//                 <input
//                   type="number"
//                   value={newIngredientAmount}
//                   onChange={(e) => setNewIngredientAmount(e.target.value)}
//                   placeholder="Mengde"
//                   className={styles.ingredientAmountInput}
//                 />
//                 {newIngredientUnit}
//                 <button onClick={handleAddIngredient} className={styles.addIngredientButton}>
//                   Legg til ingrediens
//                 </button>
//               </div>
//             )}
//           </div>

//           <div className={styles.macroSummary}>
//             <h4>Totale Makroer</h4>
//             <p>
//               <strong>Protein:</strong> {totalMacros.protein}g
//             </p>
//             <p>
//               <strong>Karbohydrater:</strong> {totalMacros.carbs}g
//             </p>
//             <p>
//               <strong>Fett:</strong> {totalMacros.fat}g
//             </p>
//             <p>
//               <strong>Kalorier:</strong> {totalMacros.kcals} kcal
//             </p>
//           </div>
//           <div className={styles.nameInput}>
//             <label>Navn på variant</label>
//             <input
//               className={styles.ingredientSearchInput}
//               placeholder="Navn på varianten"
//               value={newVariantName}
//               onChange={handleVariantNameInput}
//             />
//           </div>

//           {/* Liste over klienter tilknyttet varianten */}
//           <h4>Klienter tilknyttet varianten</h4>
//           {variantClients.map((client) => (
//             <div key={client.id} className={styles.clientItem}>
//               {client.attributes.username}
//               <button
//                 onClick={() => handleRemoveClient(client.id)}
//                 className={styles.removeClientButton}
//               >
//                 Fjern
//               </button>
//             </div>
//           ))}

//           {/* Legg til klient til varianten */}
//           <h4>Legg til klient</h4>
//           <input
//             type="text"
//             value={clientSearch}
//             onChange={(e) => setClientSearch(e.target.value)}
//             placeholder="Søk etter klient"
//             className={styles.clientSearchInput}
//           />
//           <div className={styles.filteredClients}>
//             {filteredClients.map((client) => (
//               <div
//                 key={client.id}
//                 className={`${styles.clientOption} ${
//                   selectedClient && selectedClient.id === client.id ? styles.selected : ''
//                 }`}
//                 onClick={() => setSelectedClient(client)}
//               >
//                 {client.attributes.username}
//               </div>
//             ))}
//           </div>
//           {selectedClient && (
//             <button onClick={handleAddClient} className={styles.addClientButton}>
//               Legg til {selectedClient.attributes.username}
//             </button>
//           )}
//         </Modal.Body>
//         <Modal.Footer className={styles.modalCustomFooter}>
//           <button
//             className={styles.submitButton}
//             disabled={!newVariantName.trim()}
//             onClick={() => handleSaveVariant(variant ? true : false)}
//           >
//             {variant ? 'Oppdater variant' : 'Lagre som ny variant'}
//           </button>
//           {variant && (
//             <button onClick={() => deleteVariant(variant.id)} className={styles.deleteVariantButton}>
//               Slett variant
//             </button>
//           )}
//           <button onClick={() => setShowModal(false)} className={styles.cancelButton}>
//             Avbryt
//           </button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// }


import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import Modal from 'react-bootstrap/Modal';

export default function VariantCard({
  variant,
  index,
  deleteVariant,
  clients,
  allIngredients,
  updateVariant,
  createVariant,
  mealId,
  addClientToVariant,
  removeClientFromVariant,
}) {
  const [showModal, setShowModal] = useState(false);

  // Initialiserer customIngredients med riktig struktur
  const initialCustomIngredients = (variant?.attributes?.custom_ingredients || []).map(
    (ingredient) => ({
      id: null,
      name: ingredient.ingredient_name,
      amount: ingredient.amount,
      unit: ingredient.unit,
      macros: ingredient.macros,
      ingredientId: ingredient.ingredients ? ingredient.ingredients[0] : null,
    })
  );

  const [customIngredients, setCustomIngredients] = useState(initialCustomIngredients);
  const [totalMacros, setTotalMacros] = useState({ protein: 0, carbs: 0, fat: 0, kcals: 0 });
  const [variantClients, setVariantClients] = useState(variant?.attributes?.clients?.data || []);
  const [ingredientSearch, setIngredientSearch] = useState('');
  const [filteredIngredients, setFilteredIngredients] = useState([]);
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  const [newIngredientAmount, setNewIngredientAmount] = useState('');
  const [newIngredientUnit, setNewIngredientUnit] = useState('g');
  const [newVariantName, setNewVariantName] = useState(variant?.attributes?.variant_name || '');

  // For klient-søk og valg
  const [clientSearch, setClientSearch] = useState('');
  const [filteredClients, setFilteredClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  // Oppdaterer filtrerte klienter basert på søk
  useEffect(() => {
    const searchValue = clientSearch.toLowerCase();
    setFilteredClients(
      searchValue
        ? clients.filter((client) =>
            client.attributes.username.toLowerCase().includes(searchValue)
          )
        : []
    );
  }, [clientSearch, clients]);

  const handleIngredientSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setIngredientSearch(searchValue);
    setFilteredIngredients(
      searchValue
        ? allIngredients.filter((ingredient) =>
            ingredient.attributes.name.toLowerCase().includes(searchValue)
          )
        : []
    );
  };

  const handleAddIngredient = () => {
    if (!selectedIngredient || !newIngredientAmount) return;

    const newIngredient = {
      id: null,
      name: selectedIngredient.attributes.name,
      amount: parseFloat(newIngredientAmount),
      unit: newIngredientUnit,
      macros: selectedIngredient.attributes.macros[0],
      ingredientId: selectedIngredient.id,
    };

    setCustomIngredients([...customIngredients, newIngredient]);
    setIngredientSearch('');
    setSelectedIngredient(null);
    setNewIngredientAmount('');
    setNewIngredientUnit('g');
  };

  const handleRemoveIngredient = (index) => {
    const updatedIngredients = customIngredients.filter((_, i) => i !== index);
    setCustomIngredients(updatedIngredients);
  };

  const handleVariantNameInput = (e) => {
    setNewVariantName(e.target.value);
  };

  const handleIngredientChange = (index, newAmount) => {
    const updatedIngredients = [...customIngredients];
    updatedIngredients[index] = {
      ...updatedIngredients[index],
      amount: parseFloat(newAmount) || '',
    };
    setCustomIngredients(updatedIngredients);
  };

  const calculateMacros = () => {
    let totalProtein = 0;
    let totalCarbs = 0;
    let totalFat = 0;
    let totalKcals = 0;

    customIngredients.forEach((ingredient) => {
      const { amount, macros, unit } = ingredient;

      if (!macros) return;

      if (unit === 'stk') {
        totalProtein += macros.protein * amount;
        totalCarbs += macros.carbs * amount;
        totalFat += macros.fat * amount;
        totalKcals += macros.kcals * amount;
      } else if (unit === 'g') {
        totalProtein += (macros.protein / 100) * amount;
        totalCarbs += (macros.carbs / 100) * amount;
        totalFat += (macros.fat / 100) * amount;
        totalKcals += (macros.kcals / 100) * amount;
      } else if (unit === 'dl') {
        const gramsEquivalent = amount * 100;
        totalProtein += (macros.protein / 100) * gramsEquivalent;
        totalCarbs += (macros.carbs / 100) * gramsEquivalent;
        totalFat += (macros.fat / 100) * gramsEquivalent;
        totalKcals += (macros.kcals / 100) * gramsEquivalent;
      }
    });

    setTotalMacros({
      protein: Math.round(totalProtein),
      carbs: Math.round(totalCarbs),
      fat: Math.round(totalFat),
      kcals: Math.round(totalKcals),
    });
  };

  useEffect(() => {
    calculateMacros();
    //eslint-disable-next-line
  }, [customIngredients]);

  const handleSaveVariant = async (isUpdate) => {
    const ingredientsForVariant = customIngredients.map((ingredient) => ({
      amount: ingredient.amount,
      unit: ingredient.unit,
      ingredientId: ingredient.ingredientId || ingredient.id,
      name: ingredient.name,
      macros: ingredient.macros,
      meal: [mealId],
    }));

    if (isUpdate && variant) {
      await updateVariant({
        variantId: variant.id,
        variantName: newVariantName,
        ingredients: ingredientsForVariant,
      });
    } else {
      await createVariant({
        variantName: newVariantName,
        mealId,
        ingredients: ingredientsForVariant,
      });
    }

    setShowModal(false);
  };

  // Håndterer tillegg av klient til varianten
  const handleAddClient = async () => {
    if (!selectedClient) return;
  
    // Bekreft at klienten vil bli fjernet fra andre varianter/hovedmåltidet
    const confirmed = window.confirm(`Klienten ${selectedClient.attributes.username} vil bli fjernet fra andre varianter av dette måltidet og lagt til denne varianten. Fortsette?`);
    if (!confirmed) return;
  
    await addClientToVariant({ variantId: variant.id, clientId: selectedClient.id });
    // Oppdaterer lokal tilstand
    setVariantClients([...variantClients, selectedClient]);
    setSelectedClient(null);
    setClientSearch('');
  };
  
  // Håndterer fjerning av klient fra varianten
  const handleRemoveClient = async (clientId) => {
    await removeClientFromVariant({ variantId: variant.id, clientId });
    // Oppdaterer lokal tilstand
    setVariantClients(variantClients.filter((client) => client.id !== clientId));
  };

  return (
    <>
      <div
        className={styles.variantCard}
        onClick={() => setShowModal(true)}
        key={`${variant?.id}-${index}`}
      >
        <div className={styles.variantName}>{variant?.attributes?.variant_name || 'Ny variant'}</div>
      </div>
      <Modal className={styles.modal} show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Tilpass mengder for {newVariantName || 'Ny variant'}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.container2}>
          <h3>Ingredienser</h3>
          {customIngredients.map((ingredient, index) => (
            <div key={index} className={styles.ingredientAdjustContainer}>
              <label className="col-5">{ingredient.name}</label>
              <input
                type="number"
                value={ingredient.amount}
                onChange={(e) => handleIngredientChange(index, e.target.value)}
                className={styles.ingredientInput}
              />
              <span>{ingredient.unit}</span>
              <button
                onClick={() => handleRemoveIngredient(index)}
                className={styles.removeIngredientButton}
              >
                Fjern
              </button>
            </div>
          ))}

          {/* Legg til ny ingrediens */}
          {/* <div className={styles.addIngredientContainer}>
            <h4 className="my-4">Legg til ingrediens</h4>
            <input
              type="text"
              value={ingredientSearch}
              onChange={handleIngredientSearch}
              placeholder="Søk etter ingrediens"
              className={styles.ingredientSearchInput}
            />
            <div className={styles.filteredIngredients}>
              {filteredIngredients.map((ingredient) => (
                <div
                  key={ingredient.id}
                  className={`${styles.ingredientOption} ${
                    selectedIngredient && selectedIngredient.id === ingredient.id
                      ? styles.selected
                      : ''
                  }`}
                  onClick={() => setSelectedIngredient(ingredient)}
                >
                  {ingredient.attributes.name}
                </div>
              ))}
            </div>
            {selectedIngredient && (
              <div className={styles.addIngredientDetails}>
                <input
                  type="number"
                  value={newIngredientAmount}
                  onChange={(e) => setNewIngredientAmount(e.target.value)}
                  placeholder="Mengde"
                  className={styles.ingredientAmountInput}
                />
                {newIngredientUnit}
                <button onClick={handleAddIngredient} className={styles.addIngredientButton}>
                  Legg til ingrediens
                </button>
              </div>
            )}
          </div> */}
          <div className={styles.addIngredientContainer}>
            <h4 className="my-4">Legg til ingrediens</h4>
            {!selectedIngredient ? ( // Hvis ingen ingrediens er valgt
              <>
                <input
                  type="text"
                  value={ingredientSearch}
                  onChange={handleIngredientSearch}
                  placeholder="Søk etter ingrediens"
                  className={styles.ingredientSearchInput}
                />
                <div className={styles.filteredIngredients}>
                  {filteredIngredients.map((ingredient) => (
                    <div
                      key={ingredient.id}
                      className={`${styles.ingredientOption} ${
                        selectedIngredient && selectedIngredient.id === ingredient.id ? styles.selected : ''
                      }`}
                      onClick={() => setSelectedIngredient(ingredient)} // Velg ingrediens
                    >
                      {ingredient.attributes.name}
                    </div>
                  ))}
                </div>
              </>
            ) : (
              // Når en ingrediens er valgt
              <div className={styles.addIngredientDetails}>
                <h5>Valgt ingrediens: {selectedIngredient.attributes.name}</h5>
                <input
                  type="number"
                  value={newIngredientAmount}
                  onChange={(e) => setNewIngredientAmount(e.target.value)}
                  placeholder="Mengde"
                  className={styles.ingredientAmountInput}
                />
                {newIngredientUnit}
                <div className={styles.buttonGroup}>
                  <button
                    onClick={() => {
                      handleAddIngredient();
                      setSelectedIngredient(null); // Tilbakestill valget
                      setIngredientSearch(''); // Tilbakestill søkefeltet
                    }}
                    className={styles.addIngredientButton}
                  >
                    Legg til ingrediens
                  </button>
                  <button
                    onClick={() => setSelectedIngredient(null)} // Tilbakestill valget
                    className={styles.cancelButton}
                  >
                    Avbryt
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className={styles.macroSummary}>
            <h4>Totale Makroer</h4>
            <p>
              <strong>Protein:</strong> {totalMacros.protein}g
            </p>
            <p>
              <strong>Karbohydrater:</strong> {totalMacros.carbs}g
            </p>
            <p>
              <strong>Fett:</strong> {totalMacros.fat}g
            </p>
            <p>
              <strong>Kalorier:</strong> {totalMacros.kcals} kcal
            </p>
          </div>
          <div className={styles.nameInput}>
            <label>Navn på variant</label>
            <input
              className={styles.ingredientSearchInput}
              placeholder="Navn på varianten"
              value={newVariantName}
              onChange={handleVariantNameInput}
            />
          </div>

          {/* Liste over klienter tilknyttet varianten */}
          <h4>Klienter tilknyttet varianten</h4>
          {variantClients.map((client) => (
            <div key={client.id} className={styles.clientItem}>
              {client.attributes.username}
              <button
                onClick={() => handleRemoveClient(client.id)}
                className={styles.removeClientButton}
              >
                Fjern
              </button>
            </div>
          ))}

          {/* Legg til klient til varianten */}
          <h4>Legg til klient</h4>
          <input
            type="text"
            value={clientSearch}
            onChange={(e) => setClientSearch(e.target.value)}
            placeholder="Søk etter klient"
            className={styles.clientSearchInput}
          />
          <div className={styles.filteredClients}>
            {filteredClients.map((client) => (
              <div
                key={client.id}
                className={`${styles.clientOption} ${
                  selectedClient && selectedClient.id === client.id ? styles.selected : ''
                }`}
                onClick={() => setSelectedClient(client)}
              >
                {client.attributes.username}
              </div>
            ))}
          </div>
          {selectedClient && (
            <button onClick={handleAddClient} className={styles.addClientButton}>
              Legg til {selectedClient.attributes.username}
            </button>
          )}
        </Modal.Body>
        <Modal.Footer className={styles.modalCustomFooter}>
          <button
            className={styles.submitButton}
            disabled={!newVariantName.trim()}
            onClick={() => handleSaveVariant(variant ? true : false)}
          >
            {variant ? 'Oppdater variant' : 'Lagre som ny variant'}
          </button>
          {variant && (
            <button onClick={() => deleteVariant(variant.id, variantClients[0]?.id)} className={styles.deleteVariantButton}>
              Slett variant
            </button>
          )}
          <button onClick={() => setShowModal(false)} className={styles.cancelButton}>
            Avbryt
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
