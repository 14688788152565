// import React, {useState, useEffect} from 'react';
// import Modal from 'react-bootstrap/Modal';
// import Image from 'react-bootstrap/Image';
// import css from './coachSide.module.css';
// import defaultImage from '../../../../../images/blue_man.png';
// import { API_BASE_URL, API_GRAPHQL } from '../../../../../constants';
// import { axiosAuthFetch } from '../../../../../helpers/axios';
// import Loader from '../../../loader';

// const renderStars = (rating) => {
//     const stars= [];
//     for (let i= 1; i <= 5; i++) {
//         stars.push(
//             <span key={i} className={i <= rating ? css.starFilled : css.starEmpty}>&#9733;</span>
//         );
//     }
//     return stars;
// }

// const CoachSideCheckInCards = ({ user }) => {
//     const userInfo = user.coachID;
//     const [showModal, setShowModal] = useState(false);
//     const [currentCheckIn, setCurrentCheckIn] = useState(null);
//     const [coachComment, setCoachComment] = useState('');
//     const [isLoading, setIsLoading] = useState(false);
//     const [checkIns, setCheckIns] = useState([]);

//     const query = `query {
//                         checkIns(filters: { client: { coach: { id: { eq: ${userInfo} } } } }) {
//                             data {
//                             id
//                             attributes {
//                                 date
//                                 comment
//                                 rating
//                                 coach_comment
//                                 client {
//                                 data {
//                                     id
//                                     attributes {
//                                     username
//                                     profile_picture{
//                                         data{
//                                             id
//                                             attributes{
//                                                 url
//                                             }
//                                         }
//                                     }
//                                     }
//                                 }
//                                 }
//                                 progress_pic {
//                                 data {
//                                     attributes {
//                                         url
//                                     }
//                                 }
//                                 }
//                             }
//                             }
//                         }
//                     }`

//     useEffect(() => {
//         async function getCheckIns() {
//             try {
//                 setIsLoading(true);
//                 const response = await axiosAuthFetch(`${API_GRAPHQL}`, {query}, 'POST');
//                 console.log('checkINS', response.data.checkIns.data);
//                 setCheckIns(response.data.checkIns.data);
//             } catch(error) {
//                 console.error(error);
//             } finally {
//                 setIsLoading(false);
//             }
//         }
//         getCheckIns();
//     }, [query]);

//     const handleCheckInClick = (checkIn) => {
//         setCurrentCheckIn(checkIn);
//         setCoachComment(checkIn.attributes.coach_comment || '');
//         setShowModal(true);
//     }

//     const handleSaveComment = async () => {
//         console.log("Saved comment for check-in ID:", currentCheckIn.id, "Comment:", coachComment);
//         const url = `${API_BASE_URL}/check-ins/${currentCheckIn.id}`;
//         const response = await axiosAuthFetch(url, {data:{
//                 coach_comment: coachComment
//         }}, 'PUT');
//         console.log(response);
//         alert('Operasjonen er vellykket!')
//         setShowModal(false);
//     }

//     const sortedCheckIns = [...checkIns].sort((a, b) => new Date(b.attributes.date) - new Date(a.attributes.date));

//     if(isLoading) {
//         return(
//             <Loader />
//         )
//     }

//     return (
//         <div className={css.container}>
//             {sortedCheckIns.map((checkIn) => {
//                 const { client, rating, date } = checkIn.attributes;
//                 const clientProfilePicture = client?.data?.attributes?.profile_picture?.data?.attributes?.url || defaultImage;

//                 return (
//                     <div key={checkIn.id} className={css.checkInCard} onClick={() => handleCheckInClick(checkIn)}>
//                         <div className={css.clientCheckInImage}>
//                             <Image className={css.clientProfilePicture} fluid alt="client-image" src={clientProfilePicture} />
//                         </div>
//                         <div>{client.data.attributes.username}</div>
//                         <div>{new Date(date).toLocaleDateString()}</div>
//                         <div>{renderStars(rating)}</div>
//                     </div>
//                 );
//             })}

//             {/* Modal for coach to view and comment on a check-in */}
//             <Modal size="xl" show={showModal} onHide={() => setShowModal(false)}>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Check-In Details</Modal.Title>
//                 </Modal.Header>
//                 {currentCheckIn && (
//                     <Modal.Body className={css.modalBody}>
//                         <p><strong>Dato:</strong> {new Date(currentCheckIn.attributes.date).toLocaleDateString()}</p>
//                         <p><strong>Klients kommentar:</strong> {currentCheckIn.attributes.comment}</p>
//                         <div><p><strong>Klients rangering av uka:</strong> {renderStars(currentCheckIn.attributes.rating)}</p></div>
//                         {currentCheckIn.attributes.progress_pic?.data && (
//                             <div className={css.images}>
//                                 {currentCheckIn.attributes.progress_pic.data.map((pic, index) => (
//                                     <Image
//                                         key={index}
//                                         src={pic.attributes.url}
//                                         alt={`Progress ${index}`}
//                                         className={css.image}
//                                         fluid
//                                     />
//                                 ))}
//                             </div>
//                         )}
//                         <p className="mt-5"><strong>Din kommentar:</strong></p>
//                         <textarea
//                             className={css.coachCommentTextarea}
//                             value={coachComment}
//                             onChange={(e) => setCoachComment(e.target.value)}
//                             placeholder="Skriv din kommentar her"
//                         />
//                     </Modal.Body>
//                 )}
//                 <Modal.Footer>
//                     <div className={css.saveButton} onClick={handleSaveComment}>
//                         Lagre
//                     </div>
//                     <div className={css.closeButton} onClick={() => setShowModal(false)}>
//                         Lukk
//                     </div>
//                 </Modal.Footer>
//             </Modal>
//         </div>
//     );
// }

// export default CoachSideCheckInCards;

// import React, { useState, useEffect } from 'react';
// import Modal from 'react-bootstrap/Modal';
// import Image from 'react-bootstrap/Image';
// import css from './coachSide.module.css'; // Ensure styles match client-side
// import defaultImage from '../../../../../images/blue_man.png';
// import { API_BASE_URL, API_GRAPHQL } from '../../../../../constants';
// import { axiosAuthFetch } from '../../../../../helpers/axios';
// import Loader from '../../../loader';



// const CoachSideCheckInCards = ({ user }) => {
//     const userInfo = user.coachID;
//     const [showModal, setShowModal] = useState(false);
//     const [currentCheckIn, setCurrentCheckIn] = useState(null);
//     const [coachComment, setCoachComment] = useState('');
//     const [isLoading, setIsLoading] = useState(false);
//     const [checkIns, setCheckIns] = useState([]);

//     const query = `query {
//         checkIns(filters: { client: { coach: { id: { eq: ${userInfo} } } } }) {
//             data {
//                 id
//                 attributes {
//                     date
//                     comment
//                     rating
//                     coach_comment
//                     client {
//                         data {
//                             id
//                             attributes {
//                                 username
//                                 profile_picture {
//                                     data {
//                                         id
//                                         attributes {
//                                             url
//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                     progress_pic {
//                         data {
//                             attributes {
//                                 url
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }`;

//     useEffect(() => {
//         async function getCheckIns() {
//             try {
//                 setIsLoading(true);
//                 const response = await axiosAuthFetch(`${API_GRAPHQL}`, { query }, 'POST');
//                 setCheckIns(response.data.checkIns.data);
//             } catch (error) {
//                 console.error(error);
//             } finally {
//                 setIsLoading(false);
//             }
//         }
//         getCheckIns();
//     }, [query]);

//     const handleCheckInClick = (checkIn) => {
//         setCurrentCheckIn(checkIn);
//         setCoachComment(checkIn.attributes.coach_comment || '');
//         setShowModal(true);
//     };

//     const handleSaveComment = async () => {
//         const url = `${API_BASE_URL}/check-ins/${currentCheckIn.id}`;
//         await axiosAuthFetch(url, {
//             data: {
//                 coach_comment: coachComment,
//             },
//         }, 'PUT');
//         alert('Operasjonen er vellykket!');
//         setShowModal(false);
//     };

//     const sortedCheckIns = [...checkIns].sort((a, b) => new Date(b.attributes.date) - new Date(a.attributes.date));

//     if (isLoading) {
//         return <Loader />;
//     }

//     return (
//         <div className={css.container}>
//             {sortedCheckIns.map((checkIn) => {
//                 const { client, rating, date } = checkIn.attributes;
//                 const clientProfilePicture = client?.data?.attributes?.profile_picture?.data?.attributes?.url || defaultImage;

//                 return (
//                     <div key={checkIn.id} className={css.card} onClick={() => handleCheckInClick(checkIn)}>
//                         <div className={css.clientCheckInImage}>
//                             <Image className={css.clientProfilePicture} fluid alt="client-image" src={clientProfilePicture} />
//                         </div>
//                         <div className={css.clientInfo}>
//                             <p>{client.data.attributes.username}</p>
//                             <p>{new Date(date).toLocaleDateString()}</p>
//                             <div>{renderStars(rating)}</div>
//                         </div>
//                     </div>
//                 );
//             })}

//             {currentCheckIn && (
//                 <Modal size="xl" show={showModal} onHide={() => setShowModal(false)}>
//                     <Modal.Header closeButton>
//                         <Modal.Title>Check-In Details</Modal.Title>
//                     </Modal.Header>
//                     <Modal.Body>
//                         <div className={css.modalContent}>
//                             <p><strong>Dato:</strong> {new Date(currentCheckIn.attributes.date).toLocaleDateString()}</p>
//                             <p><strong>Klients kommentar:</strong> {currentCheckIn.attributes.comment}</p>
//                             <div><p><strong>Klients rangering:</strong> {renderStars(currentCheckIn.attributes.rating)}</p></div>
//                             {currentCheckIn.attributes.progress_pic?.data && (
//                                 <div className={css.images}>
//                                     {currentCheckIn.attributes.progress_pic.data.map((pic, index) => (
//                                         <Image
//                                             key={index}
//                                             src={pic.attributes.url}
//                                             alt={`Progress ${index}`}
//                                             className={css.image}
//                                             fluid
//                                         />
//                                     ))}
//                                 </div>
//                             )}
//                             <p className="mt-5"><strong>Din kommentar:</strong></p>
//                             <textarea
//                                 className={css.coachCommentTextarea}
//                                 value={coachComment}
//                                 onChange={(e) => setCoachComment(e.target.value)}
//                                 placeholder="Skriv din kommentar her"
//                             />
//                         </div>
//                     </Modal.Body>
//                     <Modal.Footer>
//                         <div className={css.saveButton} onClick={handleSaveComment}>Lagre</div>
//                         <div className={css.closeButton} onClick={() => setShowModal(false)}>Lukk</div>
//                     </Modal.Footer>
//                 </Modal>
//             )}
//         </div>
//     );
// };

// export CoachSideCheckInCards;

import React, { useState } from 'react';
import css from './coachSide.module.css';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import { API_BASE_URL } from '../../../../../constants';
import { axiosAuthFetch } from '../../../../../helpers/axios';
import { useMediaQuery } from 'react-responsive';

const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
        stars.push(
            <span key={i} className={i <= rating ? css.starFilled : css.starEmpty}>&#9733;</span>
        );
    }
    return stars;
};

const CoachesCheckInCards = ({ checkIns }) => {
    const [showModal, setShowModal] = useState(false);
    const [currentCheckIn, setCurrentCheckIn] = useState(null); // Store the entire check-in, including id
    const [coachComment, setCoachComment] = useState('');

    const handleCardClick = (checkIn) => {
        console.log("Selected check-in:", checkIn);
        setCurrentCheckIn(checkIn); // Store the full check-in object, including id
        setCoachComment(checkIn.attributes.coach_comment || '');
        setShowModal(true);
    };

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: 'long' };
        return new Intl.DateTimeFormat('no-NO', options).format(new Date(dateString));
    };

    const handleSaveComment = async () => {
        const url = `${API_BASE_URL}/check-ins/${currentCheckIn.id}`; // Use the id from the full check-in object
        await axiosAuthFetch(url, {
            data: {
                coach_comment: coachComment,
            },
        }, 'PUT');
        alert('Operasjonen er vellykket!');
        setShowModal(false);
        window.location.reload();
    };

    const handleClose = () => setShowModal(false);

    const isDesktop = useMediaQuery({ minWidth: 1000 });

    const sortedCheckIns = [...checkIns].sort((a, b) => new Date(b.attributes.date) - new Date(a.attributes.date));

    // return (
    //     <div className={css.container}>
    //         {sortedCheckIns.map((checkIn) => {
    //             //eslint-disable-next-line
    //             const { coach_comment, comment, date, weight, progress_pic, rating_training, rating_nutrition, rating_motivation, rating_sleep, client } = checkIn.attributes;

    //             return (
    //                 <div
    //                     className={css.card}
    //                     key={checkIn.id}
    //                     onClick={() => handleCardClick(checkIn)}
    //                 >
    //                     <div className={css.checkInDate}>{formatDate(date)}</div>
    //                     <div className={css.subChild1}>
    //                         <p className={`${css.bold} my-2`}>Vekt: {weight} kg</p>
    //                     </div>
    //                     <div className={css.subChild1}>
    //                         <p className={`${css.bold} my-2`}>{client.data.attributes.username}</p>
    //                     </div>
    //                 </div>
    //             );
    //         })}
    //         {currentCheckIn && (
    //             <Modal className={css.modal} show={showModal} onHide={handleClose}>
    //                 <Modal.Header closeButton>
    //                     <Modal.Title>{currentCheckIn.attributes.client.data.attributes.username}'s check-in {formatDate(currentCheckIn.attributes.date)}</Modal.Title>
    //                 </Modal.Header>
    //                 <Modal.Body>
    //                     <div className={css.modalChild1}>
    //                         <p><strong>Dato:</strong> {formatDate(currentCheckIn.attributes.date)}</p>
    //                         <p><strong>Vekt:</strong> {currentCheckIn.attributes.weight} kg</p>
    //                         <p><strong>Trening:</strong> {renderStars(currentCheckIn.attributes.rating_training)}</p>
    //                         <p><strong>Motivasjon:</strong> {renderStars(currentCheckIn.attributes.rating_motivation)}</p>
    //                         <p><strong>Kosthold:</strong> {renderStars(currentCheckIn.attributes.rating_nutrition)}</p>
    //                         <p><strong>Søvn:</strong> {renderStars(currentCheckIn.attributes.rating_sleep)}</p>
    //                         <p><strong>Klients kommentar:</strong> {currentCheckIn.attributes.comment}</p>
    //                         <p><strong>Coach kommentar:</strong> {currentCheckIn.attributes.coach_comment}</p>
    //                     </div>
    //                     <div className={css.modalImageParent}>
    //                         {currentCheckIn.attributes.progress_pic?.data?.length > 0 && (
    //                             <div className={css.images}>
    //                                 {currentCheckIn.attributes.progress_pic.data.map((pic, index) => (
    //                                     <Image
    //                                         key={index}
    //                                         src={pic.attributes.url}
    //                                         alt={`Progress ${index}`}
    //                                         fluid
    //                                         className={css.largeImage}
    //                                     />
    //                                 ))}
    //                             </div>
    //                         )}
    //                     </div>
    //                     <textarea
    //                         className={css.coachCommentTextarea}
    //                         value={coachComment}
    //                         onChange={(e) => setCoachComment(e.target.value)}
    //                         rows="6"
    //                         placeholder="Skriv din kommentar her"
    //                     />
    //                 </Modal.Body>
    //                 <Modal.Footer>
    //                     <div className={css.saveButton} onClick={handleSaveComment}>Lagre</div>
    //                     <div className={css.closeButton} onClick={() => setShowModal(false)}>Lukk</div>
    //                 </Modal.Footer>
    //             </Modal>
    //         )}
    //     </div>
    // );

    return (
        <div className={css.container}>
            {isDesktop ? (
                // Desktop layout
                <div className={css.desktopContainer}>
                    {sortedCheckIns.map((checkIn) => {
                        const {
                            //eslint-disable-next-line
                            coach_comment,comment,date,weight,progress_pic,rating_training,rating_nutrition,rating_motivation,rating_sleep,
                            client,
                        } = checkIn.attributes;
    
                        return (
                            <div
                                className={css.desktopCard}
                                key={checkIn.id}
                                onClick={() => handleCardClick(checkIn)}
                            >
                                <p className={`${css.bold} my-2`}>{client.data.attributes.username}</p>
                                <p className={`${css.bold} my-2`}>Vekt: {weight} kg</p>
                                <div className={css.checkInDate}>{formatDate(date)}</div>
                            </div>
                        );
                    })}
                </div>
            ) : (
                sortedCheckIns.map((checkIn) => {
                    const {
                        //eslint-disable-next-line
                        coach_comment,comment,date,weight,progress_pic,rating_training,rating_nutrition,rating_motivation,rating_sleep,
                        client,
                    } = checkIn.attributes;
    
                    return (
                        <div
                            className={css.card}
                            key={checkIn.id}
                            onClick={() => handleCardClick(checkIn)}
                        >
                            <div className={css.checkInDate}>{formatDate(date)}</div>
                            <div className={css.subChild1}>
                                <p className={`${css.bold} my-2`}>Vekt: {weight} kg</p>
                            </div>
                            <div className={css.subChild1}>
                                <p className={`${css.bold} my-2`}>{client.data.attributes.username}</p>
                            </div>
                        </div>
                    );
                })
            )}
            {currentCheckIn && (
                <Modal className={css.modal} show={showModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {currentCheckIn.attributes.client.data.attributes.username}'s check-in {formatDate(currentCheckIn.attributes.date)}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={css.modalChild1}>
                            <p><strong>Dato:</strong> {formatDate(currentCheckIn.attributes.date)}</p>
                            <p><strong>Vekt:</strong> {currentCheckIn.attributes.weight} kg</p>
                            <p><strong>Trening:</strong> {renderStars(currentCheckIn.attributes.rating_training)}</p>
                            <p><strong>Motivasjon:</strong> {renderStars(currentCheckIn.attributes.rating_motivation)}</p>
                            <p><strong>Kosthold:</strong> {renderStars(currentCheckIn.attributes.rating_nutrition)}</p>
                            <p><strong>Søvn:</strong> {renderStars(currentCheckIn.attributes.rating_sleep)}</p>
                            <p><strong>Klients kommentar:</strong> {currentCheckIn.attributes.comment}</p>
                            <p><strong>Coach kommentar:</strong> {currentCheckIn.attributes.coach_comment}</p>
                        </div>
                        <div className={css.modalImageParent}>
                            {currentCheckIn.attributes.progress_pic?.data?.length > 0 && (
                                <div className={css.images}>
                                    {currentCheckIn.attributes.progress_pic.data.map((pic, index) => (
                                        <Image
                                            key={index}
                                            src={pic.attributes.url}
                                            alt={`Progress ${index}`}
                                            fluid
                                            className={css.largeImage}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                        <textarea
                            className={css.coachCommentTextarea}
                            value={coachComment}
                            onChange={(e) => setCoachComment(e.target.value)}
                            rows="6"
                            placeholder="Skriv din kommentar her"
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <div className={css.saveButton} onClick={handleSaveComment}>Lagre</div>
                        <div className={css.closeButton} onClick={() => setShowModal(false)}>Lukk</div>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
};

export default CoachesCheckInCards;
